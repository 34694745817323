import {useState} from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
const Login = (props) => {
    const {setLoggedIn} = props
    const [pass,setPass] = useState(null)

    const checkPassword = () => {
        if(pass === "MartinaSolimine") {
            setLoggedIn(true)
        } 
        else {
            setLoggedIn(false)
        }
    }
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Avatar alt="Remy Sharp" src="https://lh3.googleusercontent.com/pw/AM-JKLWwQviuBGtvff_g6Kz8qjAa4cyMtQSS5Y-4fUnwYF18aJoRtgRPo-5Cfe3kjiheGwTvifnHHdv6CZWrudmzAgJTvaTtUhZbCkBY6-7yuYGO-LUAH9zVL3TzKU0wiGg0XIYd2QjFO8sIuq1_OpU4h1o=w1197-h937-no?authuser=0" 
               style={{width: '20vh', height: '20vh', marginTop: '5vh'}} />
            </div>
            <div style={{marginTop: '10px'}}>
                <TextField 
                    
                    id="outlined-basic" 
                    label="Password" 
                    variant="outlined" 
                    onChange={(e) => {setPass(e.target.value)}}
                    />
            </div>
            <div>
            <Button variant="outlined" onClick={() => {
                checkPassword()
            }} style={{minWidth: '210px', minHeight: '50px', marginTop: '10px'}}>Login</Button>
            </div>
        </div>
    )
}

export default Login