import React, {useState, useEffect} from  "react";
import Elemento from './Elemento'
import Tabella from './Tabella'
const Lista = (props) => {

    const {lista} = props;
    const [order,setOrder] = useState('asc')
    
    //return (<Tabella lista={lista}/>)

    return (

        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
        {
        lista.map((el,index) => {
        return <Elemento key={index} {...el}/>})
        }
        </div>
    )

}

export default Lista