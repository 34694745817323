import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function BasicTable(props) {
  const classes = useStyles();

  const {lista} = props;


  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Codice</TableCell>
            <TableCell align="center">Nome</TableCell>
            <TableCell align="center">Prezzo</TableCell>
            <TableCell align="center">Link</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lista.map((row) => { 
              
              const {codice,nome,qty,prezzo,disponibile, foto, descrizione, link} = row

              return (
            <TableRow key={codice}>
              <TableCell align="center" component="th" scope="row" style={{maxWidth: '100px'}}>
                {codice}
              </TableCell>
              <TableCell align="center">{nome}</TableCell>
              <TableCell align="center">{prezzo}</TableCell>
              <TableCell align="center"><a href={link}>VAI AL SITO</a></TableCell>
            </TableRow>
          )}
          
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}