import logo from './logo.svg';
import './App.css';
import Lista from './martina/Lista'
import {useState, useEffect} from 'react'
import Login from './martina/LogIn'
const axios = require('axios').default;



function App() {

    const [lista,setLista] = useState([])
    const [loggedIn,setLoggedIn] = useState(window.localStorage.getItem("logged"))

    const setLogged = (logged) => {
      console.log({logged})
      window.localStorage.setItem("logged",logged)
      setLoggedIn(logged)

    }

    useEffect(() => {

      //axios.get('https://sheet.best/api/sheets/681c6c83-387a-4122-8037-a820baf5a33f')
      axios.get('https://script.googleusercontent.com/macros/echo?user_content_key=HQWlzxPqjpVqQJUlNuAwUpX7cf6wPDhqvIYvQBbMPnCx8hWLgpvcPOZJ4f32wLjMYrTJZ0rn5Gp3--Ioiy3co0WvK9IGt7Ifm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnI4pDdASGCk6YA3taJ5ENlMQ_FZZr6PlClFgep0UcFGgJbUrATRzCF_rSrHOtzRK0_am98ZKB5KtHBPc5YaA6UGFn4GZB-dwUelMT85eBSpMt85Qtxpr16Y&lib=MhYEpLehUKlC9izSKtwkc8ttJK623vNo6')
    .then(function (response) {
      const data = response.data.map((el) => {return {...el, prezzo: Number(el.prezzo)}}).sort((a,b) => {return a.prezzo - b.prezzo})
      setLista(data)
    })

    },[])



  return (
    <div className="App">
     {(!loggedIn || loggedIn === "false") && <Login setLoggedIn={setLogged}/>}
     {(loggedIn && loggedIn !== "false" && lista.length < 1) && <div><strong>
       Aspetta un attimo, stò recuperando i dati dal supermegawow lentissimo server, la prossima settimana sarà più veloce, forse...
      </strong></div>}
     {(loggedIn && loggedIn !== "false") && <Lista lista={lista} />}
    </div>
  );
}

export default App;
