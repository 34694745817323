import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
  root: {
    width: '20vw',
    minWidth: 350,
    marginBottom: 5
  },
  media: {
    height: 300,
  },
  noimage: {
    height: 40
  }
});

export default function MediaCard(props) {
  const classes = useStyles();

  const {codice,nome,qty,prezzo,disponibile, foto, descrizione, link} = props

  console.log({nome,link})
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={(foto && foto.length > 1) ? classes.media : classes.noimage}
          image={foto}
          title={nome}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h6">
           {nome} - {prezzo} €
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          {descrizione}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
           cod: {codice}
        </Button>
        <Button size="small" color="primary">
           {(link && link.length > 1) && <Link href={link}>
                VAI AL SITO
            </Link>}
        </Button>
      </CardActions>
    </Card>
  );
}